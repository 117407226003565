import './SiteFooter.scss';
import * as React from "react";
import { Link } from 'gatsby';
import { baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import LinkWrapper from 'components/texty/LinkWrapper';


interface Props {
    narrow?: boolean;
    fixed?: FixMode;
    className?: string;
    hideOnMobile?: boolean;
}

export enum FixMode {
    NotFixed,
    FixOnMobile,
    FixOnDesktop,
    FixAlways
}

const baseClass = 'site-footer';

const SiteFooter: React.FunctionComponent<Props> = (props) => {
    const modifiers: string[] = [];
    if (props.narrow === true) {
        modifiers.push('narrow');
    }
    if (props.hideOnMobile === true) {
        modifiers.push('mobile-hide');
    }
    if (props.fixed) {
        switch (props.fixed) {
            case FixMode.FixAlways:
                modifiers.push('fixed');
                break;
            case FixMode.FixOnMobile:
                modifiers.push('fixed-mobile');
                break;
            case FixMode.FixOnDesktop:
                modifiers.push('fixed-desktop');
                break;
        }
    }
    return <footer className={[baseClassAnd(baseClass, props), baseClassAnd(baseClass, props, ...modifiers)].join(' ')}>
        <nav>
            <LinkWrapper to='/privacy-policy/'>Privacy Policy</LinkWrapper>
            <div className="footer-text"><p>© 2020 ModelShade</p><p>Third party logos are trademarks of their respective owners.  All rights reserved.</p></div>
            <LinkWrapper to='/cookies/'>Cookies</LinkWrapper>
        </nav>
    </footer>

}

export default SiteFooter;