import React from "react"
import "./_CommonShared.scss";
import "./HomePageLayout.scss";
import { BigWavyCurveSvg, ArticleWaveSvg } from "components/svg";
import LanguageButton from "components/buttons/LanguageButton";
import ContactForm from "components/forms/ContactForm";
import SiteFooter from "components/headersfooters/SiteFooter";
import PageMeta from "components/texty/PageMeta";
import { graphql } from "gatsby"
import { childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { GlobalStateContext } from '@Contexts';
import { getCompanyStructuredData } from "@jcharlesworthuk/your-mum-frontend/components/seo/CompanyStructuredData";
import LinkWrapper from "components/texty/LinkWrapper";
import { mapToWebsiteInformation } from "graphql/graphql-mappers";
import { Site } from "@GraphQLModels";
import LatestArticles from "components/static/LatestArticles";

type Props = {
    pageContext: {
    };
    data: {
        site?: Site
    }
}

const baseClass = 'home-view';
const childClass = childClassClosure(baseClass);

const HomePageLayout: React.FunctionComponent<Props> = (props: Props) => {
    const { lang } = React.useContext(GlobalStateContext);
    const website = mapToWebsiteInformation(props.data.site.siteMetadata);

    return <div className={baseClass}>
        <PageMeta
            route='/'>
            <script className='structured-data-list' type="application/ld+json">{getCompanyStructuredData(website)}</script>
        </PageMeta>
        <BigWavyCurveSvg className={childClass('waves')} />
        <div className={childClass('logo-block')}>
            <LanguageButton />
            <img className={childClass('logo')} alt='ModelShade Paint Conversion Charts' src="/assets/fixed/modelshade.svg" />
        </div>
        <section className={childClass('hero')}>
            <div className={childClass('hero-title')}>
                <h1>Model Paint Conversion Charts</h1>
                <p className="standfirst">Welcome to ModelShade, the most comprehensive conversion tool for model paint {lang.colour}s</p>
                <aside>Convert to and from Tamiya, Humbrol, Mig, Vallejo Model Color, Vallejo Model Air, MrHobby, AK Interactive and Testors</aside>
            </div>
            <div className={childClass('hero-cta-block')}>
                <LinkWrapper to={'/paint-conversion-chart/'} className={childClass('hero-cta')}>Get Started</LinkWrapper>
            </div>
        </section>
        <section className={childClass('section', 'first')}>
            <h3 className={childClass('section-title')}>Latest Updates</h3>
            <ul className={childClass('latest-updates')}>
                <li className={childClass('latest-updates-item')}>
                    <h4>22nd October 2020 - Look and feel refreshed</h4>
                    <p>We've refreshed the look of the results page thanks to some great feedback from users that the previous one was difficult to read. <a href="#ContactUs">Let us know</a> what you think we are always keen to hear ideas for improving the conversion tool</p>
                </li>
                <li className={childClass('latest-updates-item')}>
                    <h4>15th October 2020 - Added AK Interactive 3rd Gen</h4>
                    <p>Thanks for all your great feedback and so many people asking for AK Interactive's 3rd generation acrylic paints we have now added these to the converter</p>
                </li>
                <li className={childClass('latest-updates-item')}>
                    <h4>7th November 2019 - You can now match from RAL and Federal Standard and Luftwaffe RLM {lang.colour} codes</h4>
                    <p>We have added data for RAL, RLM and Federal Standard {lang.colour} codes into the options, Select them from the "Convert From" table.  If you have other paint codes you want to convert from then <a href="#ContactUs">get in touch</a> and let us know</p>
                </li>
                <li className={childClass('latest-updates-item')}>
                    <h4>9th October 2019 - We are on Twitter! <img src='/assets/fixed/icon-twitter.png' alt='Twitter' /></h4>
                    <p>Follow <strong><a href="https://www.twitter.com/ModelShade">@ModelShade</a></strong> on Twitter for updates to the paint conversion tool and other model related content</p>
                </li>
            </ul>
        </section>
        <section className={childClass('section')}>
            <h2 className={childClass('section-title')}>How it works</h2>
            <section className={childClass('how-it-works', 'one')}>
                <p>Select a paint manufacturer to convert from</p>
            </section>
            <section className={childClass('how-it-works', 'two')}>
                <p>Search for a source paint and see the closest matches across all the paint manufacturers we have data for</p>
            </section>
            <section className={childClass('how-it-works', 'three')}>
                <p>Each match is rated based on how many conversion charts it appears on, combined with the results of our {lang.colour} matching algorithm.</p>
            </section>
        </section>
        <section className={childClass('body-cta-container')}>
            <LinkWrapper to={'/paint-conversion-chart/'} className={childClass('body-cta')}>Get Started</LinkWrapper>
        </section>
        <section className={childClass('section')}>
            <h3 className={childClass('section-title')}>Paint Matching Algorithm</h3>
            <p>The matching tool will attempt to find multiple matches for each paint, so alongside official chart matches we also show {lang.colour}s matched using the CIE94 {lang.colour} matching algorithm.  CIE94 is an algorithm devised by the <a href="http://www.cie.co.at/index.php" target="_blank" rel="noopener noreferrer">International Commission on Illumination</a> as a way to judge difference in perceived {lang.colour}.  We use {lang.colour} information for over 2000 paints obtained directly from manufacturer's websites, so although representing paint {lang.colour} on a computer/mobile screen is far from perfect, it really is the best we can do.</p>
        </section>
        <div className={childClass('coloured-row')}>
            <ContactForm />
        </div>
        <ArticleWaveSvg className={childClass('breakup-waves')} />
        <section className={childClass('section')}>
            <h3 className={childClass('section-title')}>Articles &amp; Guides from ModelShade</h3>
            <LatestArticles className={childClass('article-list')} />
        </section>
        {/* <section className={childClass('section', 'no-top-space')}>
        <h3 className={childClass('section-title')}></h3>
        <ul className={layoutChildClass('article-list')}>
            {this.props.articles.map((article, index) => <li key={index} className={layoutChildClass('article-list-item')}>
                <ArticleLinkCard articleMeta={article} inPanel={false} />
            </li>)}
        </ul>
    </section>
    <aside className={childClass('section')}>
        <div className={layoutChildClass('callout-box')}>
            <h2>Looking for the paint converter?</h2>
            <p>Our paint conversion tool will help you convert between over 2000 different paint products</p>
            <div className='cta-container'>
                <LinkWrapper to={'/paint-conversion-chart/'} className='cta'>Click Here To Begin</LinkWrapper>
            </div>
        </div>
    </aside> */}
        <SiteFooter narrow={true} />
    </div>
}

export default HomePageLayout;

export const pageQuery = graphql`
  query HomePageQuery {
        site {
            siteMetadata {
                title
                siteUrl
                siteLogoUrl
                siteLogoUrlSquare
                titleTemplate
                social {
                  twitter {
                      handle,
                      url
                  }
                }
            }
        }
        allFile( filter: { dir: { regex: "/content\/articles/" } } ) {
            edges {
              node {
                childMdx { 
                  id
                  fields {
                    route
                  }
                  timeToRead
                  frontmatter {
                    title
                    standfirst
                    date
                    mainImage
                  }
                }
                name
                relativeDirectory
              }
            }
        } 
    }
  `

