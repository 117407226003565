import * as React from "react";
import './ContactForm.scss';

type Props = {};


const baseClass = 'contact-form';

function childClass(name: string, modifiers?: string) {
    const base = `${baseClass}__${name}`;
    if (!modifiers) return base;
    return `${base} ${modifiers}`;
}

class ContactForm extends React.Component<Props, {}> {

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return <div className={baseClass} id="ContactUs">
            <h2>Send Us Your Thoughts</h2>
            <p>We are really keen to hear your feedback, ideas for improvements, bug reports, or anything else you'd like to say! Get in touch by filling out this form</p>
            <form className={childClass('form')} name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact" />
                <div className={childClass('field')}>
                    <label htmlFor="name">Your Name</label>
                    <input type="text" name="name" required />
                </div>
                <div className={childClass('field')}>
                    <label htmlFor="email">Your Email</label>
                    <input type="email" name="email" required />
                </div>
                <div className={childClass('field')}>
                    <label htmlFor="message">Message</label>
                    <textarea name="message" required></textarea>
                </div>
                <button type="submit">Send</button>
            </form>
        </div>
    }
}

export default ContactForm;