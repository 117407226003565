import './LanguageButton.scss';
import * as React from "react";
import { UkFlagSvg, UsFlagSvg } from 'components/svg';
import * as ReactGA from 'react-ga';
import { GaEventCategory } from '@Models';
import { GlobalStateContext, GlobalStateActionType } from '@Contexts';
import { setPreferredLanguage } from '@Functions';

type Props = {};

const baseClass = 'language-button';

const LanguageButton: React.FunctionComponent<Props> = () => {
    const { spellingKey, dispatch } = React.useContext(GlobalStateContext);
    const onClick = () => {
        switch (spellingKey) {
            case 'us': {
                const newKey = 'uk';
                setPreferredLanguage(newKey);
                ReactGA.event({ category: GaEventCategory.Behaviour, action: 'Change language', label: newKey });
                dispatch({ type: GlobalStateActionType.SelectLanguage, spellingKey: newKey });
                break;
            }
            case 'uk': {
                const newKey = 'us';
                setPreferredLanguage(newKey);
                ReactGA.event({ category: GaEventCategory.Behaviour, action: 'Change language', label: newKey });
                dispatch({ type: GlobalStateActionType.SelectLanguage, spellingKey: newKey });
                break;
            }
        }
    }

    return <button className={baseClass} type='button' onClick={onClick}>
    { spellingKey == 'us' && <UsFlagSvg /> }
    { spellingKey == 'uk' && <UkFlagSvg /> }
    <span className="tooltiptext">Changes the spelling of "colour"</span>
        </button>
}

export default LanguageButton;